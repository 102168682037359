import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { captureManualSentryException } from "src/common/sentry";
import { useScrollToTop } from "src/common/useScrollToTop";
import { Button, PageTitle, SkeletonLoading } from "src/components";
import styles from "src/pages/CateringInvoice/styles.module.scss";
import { State } from "src/state/state";
import { getCateringInvoiceFromDatabaseAction } from "src/state/cateringInvoice/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleInfo,
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { formatISOStringToLongDateAndTime } from "src/common/date";
import classNames from "classnames";
import {
  calculateSalesTaxRateForCateringRequest,
  roundToNearestCent,
} from "src/common/price";
import { formatNumber } from "src/common/number";
import Modal from "react-awesome-modal";
import { getServiceFeeForCateringRequest } from "src/common/serviceFee";
import { createPaymentIntentForCateringInvoice } from "src/common/catering";
import { getEnvVariable } from "src/config/getConfig";
import { loadStripe } from "@stripe/stripe-js";
import { RestaurantFragment } from "src/state/restaurant/types";
import { useDesign } from "src/common/getDesign";
import palette from "src/common/styles/palette.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentDetails } from "src/pages/CateringInvoice/PaymentDetails/PaymentDetails";

type CateringInvoiceUrlParams = "cateringInvoiceId";

export const CateringInvoice = () => {
  useScrollToTop();

  const design = useDesign();
  const { cateringInvoiceId } = useParams<CateringInvoiceUrlParams>();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const [paymentTypeSelected, setPaymentTypeSelected] = useState<
    "card" | "bank_account"
  >("bank_account");
  const [salesTaxRate, setSalesTaxRate] = useState(0);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>();
  const [isLoadingACHPaymentIntent, setIsLoadingACHPaymentIntent] =
    useState(false);
  const [isLoadingCardPaymentIntent, setIsLoadingCardPaymentIntent] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [successfullyPaid, setSuccessfullyPaid] = useState(false);
  const [isFeesModalVisible, setIsFeesModalVisible] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const cateringInvoice = useSelector(
    (state: State) =>
      cateringInvoiceId && state.cateringInvoices[cateringInvoiceId],
  );

  const stripePromise = loadStripe(getEnvVariable("STRIPE_PUBLISHABLE_KEY"), {
    stripeAccount: restaurant.stripeConnectedAccountId || undefined,
  });

  const fetchPaymentIntentForBankAccount = useCallback(async () => {
    if (cateringInvoiceId) {
      setIsLoadingACHPaymentIntent(true);
      setPaymentTypeSelected("bank_account");
      const paymentIntentDetails = await createPaymentIntentForCateringInvoice(
        cateringInvoiceId,
        "bank_account",
      );

      setPaymentIntentClientSecret(
        paymentIntentDetails.paymentIntentClientSecret,
      );
      setIsLoadingACHPaymentIntent(false);
    }
  }, [cateringInvoiceId]);

  const fetchPaymentIntentForCard = useCallback(async () => {
    if (cateringInvoiceId) {
      setIsLoadingCardPaymentIntent(true);
      setPaymentTypeSelected("card");
      const paymentIntentDetails = await createPaymentIntentForCateringInvoice(
        cateringInvoiceId,
        "card",
      );

      setPaymentIntentClientSecret(
        paymentIntentDetails.paymentIntentClientSecret,
      );
      setIsLoadingCardPaymentIntent(false);
    }
  }, [cateringInvoiceId]);

  const fetchCateringInvoice = useCallback(async () => {
    if (cateringInvoiceId) {
      const cateringInvoice =
        await getCateringInvoiceFromDatabaseAction(cateringInvoiceId)(dispatch);

      if (cateringInvoice.response.isPaid) {
        setSuccessfullyPaid(true);
        setIsLoading(false);
        return;
      }

      const [salesTax, paymentIntentDetails] = await Promise.all([
        calculateSalesTaxRateForCateringRequest(
          cateringInvoice.response.cateringRequestId,
        ),
        createPaymentIntentForCateringInvoice(
          cateringInvoiceId,
          "bank_account",
        ),
      ]);

      setPaymentIntentClientSecret(
        paymentIntentDetails.paymentIntentClientSecret,
      );
      setSalesTaxRate(salesTax);
    }

    setIsLoading(false);
  }, [cateringInvoice]);

  const salesTaxAmount = useMemo(() => {
    if (!cateringInvoice) {
      return 0;
    }

    return roundToNearestCent(cateringInvoice.subtotalInDollars * salesTaxRate);
  }, [cateringInvoice, salesTaxRate]);

  const serviceFee = useMemo(() => {
    if (!cateringInvoice) {
      return 0;
    }

    return getServiceFeeForCateringRequest(cateringInvoice.subtotalInDollars);
  }, [cateringInvoice]);

  const taxesAndFees = useMemo(() => {
    return roundToNearestCent(salesTaxAmount + serviceFee);
  }, [salesTaxAmount, serviceFee]);

  const orderTotal = useMemo(() => {
    if (!cateringInvoice) {
      return 0;
    }

    return roundToNearestCent(
      cateringInvoice.subtotalInDollars +
        taxesAndFees +
        cateringInvoice.tipInDollars,
    );
  }, [cateringInvoice, taxesAndFees]);

  useEffect(() => {
    if (!cateringInvoice) {
      fetchCateringInvoice();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.CateringInvoice}>
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  if (!cateringInvoiceId || !cateringInvoice) {
    captureManualSentryException(
      new Error(
        "cateringInvoice or cateringInvoiceId is not defined in CateringInvoice",
      ),
    );
    return <div />;
  }

  if (successfullyPaid) {
    return (
      <div
        className={styles.CateringInvoice}
        data-testid={`catering-invoice-container-${cateringInvoice.id}`}
      >
        <PageTitle
          title={`Catering Invoice #${cateringInvoice.cateringRequest.cateringNumber}`}
          testId="page-title"
        />
        <div
          className={styles.successfullyPaid}
          data-testid="successfully-paid-screen"
        >
          <FontAwesomeIcon className={styles.checkIcon} icon={faCheckCircle} />
          <h3 className={styles.successfullyPaidText}>
            This invoice payment has been initiated. If your payment succeeds,
            the receipt will be sent to your email.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.CateringInvoice}
      data-testid={`catering-invoice-container-${cateringInvoice.id}`}
    >
      <PageTitle
        title={`Catering Invoice #${cateringInvoice.cateringRequest.cateringNumber}`}
        testId="catering-invoice-title"
      />
      <div className={styles.container}>
        <div className={styles.cateringDetailsContainer}>
          <h2 className={styles.titleText}>Catering Details</h2>
          <p className={styles.disclaimerText}>
            Contact us with any issues regarding items or prices. Sales are
            final.
          </p>
          <div className={styles.cateringDetailsRow}>
            <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
            <div className={styles.contentRow}>
              <h4
                className={styles.cateringInfoTitle}
              >{`Location${isDesktop ? ": " : ""}`}</h4>
              <h4
                className={styles.cateringInfoDetail}
                data-testid="event-location"
              >
                {cateringInvoice.cateringRequest.eventLocation}
              </h4>
            </div>
          </div>
          <div className={styles.cateringDetailsRow}>
            <FontAwesomeIcon className={styles.icon} icon={faClock} />
            <div className={styles.contentRow}>
              <h4
                className={styles.cateringInfoTitle}
              >{`Event Date${isDesktop ? ": " : ""}`}</h4>
              <h4
                className={styles.cateringInfoDetail}
                data-testid="event-date"
              >
                {formatISOStringToLongDateAndTime(
                  cateringInvoice.cateringRequest.eventDate,
                )}
              </h4>
            </div>
          </div>
        </div>
        <div className={styles.bottomSection}>
          {cateringInvoice.lineItems.map((lineItem, index) => (
            <div className={styles.lineItem} key={index}>
              <h4
                className={styles.lineItemTitle}
                data-testid={`line-item-${index}-title`}
              >
                {lineItem.title}
              </h4>
              <h4
                className={styles.lineItemAmount}
                data-testid={`line-item-${index}-amount`}
              >
                {formatNumber(lineItem.amountInDollars)}
              </h4>
            </div>
          ))}
          <div className={styles.receiptDetails}>
            <div className={styles.receiptDetailRow}>
              <h4 className={styles.receiptText}>Subtotal</h4>
              <h4 className={styles.receiptText} data-testid="subtotal-amount">
                {formatNumber(cateringInvoice.subtotalInDollars)}
              </h4>
            </div>
            <div className={styles.receiptDetailRow}>
              <div className={styles.taxesAndFeesRow}>
                <h4 className={styles.receiptText}>Taxes & Fees</h4>
                <FontAwesomeIcon
                  data-testid="taxes-and-fees-info-icon"
                  className={styles.circleInfoIcon}
                  icon={faCircleInfo}
                  onClick={() => {
                    setIsFeesModalVisible(true);
                  }}
                />
              </div>
              <h4
                className={styles.receiptText}
                data-testid="taxes-and-fees-amount"
              >
                {formatNumber(taxesAndFees)}
              </h4>
            </div>
            {cateringInvoice.tipInDollars > 0 ? (
              <div className={styles.receiptDetailRow}>
                <h4 className={styles.receiptText}>Included Tip</h4>
                <h4 className={styles.receiptText} data-testid="tip-amount">
                  {formatNumber(cateringInvoice.tipInDollars)}
                </h4>
              </div>
            ) : null}
            <div
              className={classNames(
                styles.receiptDetailRow,
                styles.bottomBorder,
              )}
            >
              <h4 className={styles.receiptBoldText}>Order Total</h4>
              <h4 className={styles.receiptBoldText} data-testid="total-amount">
                {formatNumber(orderTotal)}
              </h4>
            </div>
          </div>
          {isLoadingACHPaymentIntent || isLoadingCardPaymentIntent ? (
            <PaymentDetails
              withStripeHooks={false}
              isLoadingACHPaymentIntent={isLoadingACHPaymentIntent}
              isLoadingCardPaymentIntent={isLoadingCardPaymentIntent}
              onSelectACH={fetchPaymentIntentForBankAccount}
              onSelectCard={fetchPaymentIntentForCard}
              paymentTypeSelected={paymentTypeSelected}
              onPaymentSuccess={() => {
                setSuccessfullyPaid(true);
              }}
              cateringInvoiceId={cateringInvoiceId}
            />
          ) : (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntentClientSecret,
                appearance: {
                  theme: "stripe" as "stripe" | "night",
                  variables: {
                    colorPrimary: design.buttonColor,
                    colorBackground: design.cardColor,
                    colorText: design.textColor,
                    colorDanger: palette.red,
                    spacingUnit: "4px",
                    borderRadius: "4px",
                  },
                },
              }}
            >
              <PaymentDetails
                withStripeHooks={true}
                isLoadingACHPaymentIntent={isLoadingACHPaymentIntent}
                isLoadingCardPaymentIntent={isLoadingCardPaymentIntent}
                onSelectACH={fetchPaymentIntentForBankAccount}
                onSelectCard={fetchPaymentIntentForCard}
                paymentTypeSelected={paymentTypeSelected}
                onPaymentSuccess={() => {
                  setSuccessfullyPaid(true);
                }}
                cateringInvoiceId={cateringInvoiceId}
              />
            </Elements>
          )}
        </div>
      </div>
      <Modal
        visible={isFeesModalVisible}
        width={isDesktop ? "30%" : "80%"}
        height={isDesktop ? "400" : "425"}
        effect="fadeInUp"
        onClickAway={() => setIsFeesModalVisible(false)}
      >
        <div
          className={styles.taxesAndFeesModal}
          data-testid="taxes-and-fees-modal"
        >
          <h3 className={styles.modalTitle}>{"Taxes & Fees"}</h3>
          <div className={styles.modalContent}>
            <div className={styles.modalSection}>
              <div className={styles.modalSectionRow}>
                <p
                  className={styles.modalText}
                >{`Sales Tax (${(salesTaxRate * 100).toFixed(2)}%)`}</p>
                <p
                  className={styles.modalText}
                  data-testid="modal-sales-tax-amount"
                >
                  {formatNumber(salesTaxAmount)}
                </p>
              </div>
              <p className={styles.modalSubtext}>
                This is the state sales tax amount that is required to be
                collected based on your event location.
              </p>
            </div>
            <div className={styles.modalSection}>
              <div className={styles.modalSectionRow}>
                <p className={styles.modalText}>{`Service Fee`}</p>
                <p
                  className={styles.modalText}
                  data-testid="modal-service-fee-amount"
                >
                  {formatNumber(serviceFee)}
                </p>
              </div>
              <p className={styles.modalSubtext}>
                This service fee helps us process catering orders and covers our
                website & digital expenses.
              </p>
            </div>
            <div className={styles.modalSectionBoldRow}>
              <p className={styles.modalBoldText}>{`Taxes & Fees`}</p>
              <p className={styles.modalBoldText} data-testid="modal-total">
                {formatNumber(taxesAndFees)}
              </p>
            </div>
          </div>
          <Button
            testId="modal-ok-button"
            className={styles.modalButton}
            onClick={() => setIsFeesModalVisible(false)}
          >
            <h3 className={styles.modalButtonText}>Ok</h3>
          </Button>
        </div>
      </Modal>
    </div>
  );
};
