// Types for restaurant settings

export enum CATERING_CONTACT_TYPE {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export interface DeliverySettingsFragment {
  enabled: boolean;
  pickupInstructions: string;
}

export interface RestaurantSettingsFragment {
  id: string;
  cateringContactType: CATERING_CONTACT_TYPE;
  isCateringSchedulingFormEnabled: boolean;
  minPreparationTime: number;
  deliverySettings: DeliverySettingsFragment;
  isOnlineOrderingEnabled: boolean;
  isRestaurantTipsEnabled: boolean;
  isAddressShown: boolean;
  allowScheduledOrders: boolean;
}
