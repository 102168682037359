import { useSelector } from "react-redux";
import styles from "src/components/Footer/styles.module.scss";
import { State } from "src/state/state";
import { getClientConfig } from "src/state/clientConfig/utils";
import classNames from "classnames";
import { Image } from "src/components";
import { captureManualSentryException } from "src/common/sentry";
import {
  getDownloadFromTheAppStoreImageURL,
  getGetItOnGooglePlayImageURL,
} from "src/common/s3";
import { useMemo } from "react";
import { daysOfWeek, formatISOStringToHoursAndMinutes } from "src/common/date";
import {
  logClickFooterDisclaimerToAnalytics,
  logFooterAppLinkClickedToAnalytics,
  logFooterContactLinkClickedToAnalytics,
  logFooterPlatterLinkClickedToAnalytics,
  logFooterSocialMediaLinkClickedToAnalytics,
} from "src/common/analytics";
import { SocialIcon } from "react-social-icons";
import { CustomSocialIcon } from "src/components/Footer/CustomSocialIcon/CustomSocialIcon";
import { formatPhoneNumber } from "src/common/phone";

export const Footer = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation,
  );

  const hoursOfOperationTextArray = useMemo(() => {
    if (!selectedLocation) {
      return [];
    }

    const arrayOfStrings: string[] = [];

    for (const day of daysOfWeek) {
      const key = day.toLowerCase();

      if (!selectedLocation.hoursOfOperation[key]) {
        arrayOfStrings.push(`${day}: Closed`);
        continue;
      }

      let stringToPush = "";
      for (const hours of selectedLocation.hoursOfOperation[key]) {
        const openTime = formatISOStringToHoursAndMinutes(hours.open);
        const closeTime = formatISOStringToHoursAndMinutes(hours.close);

        stringToPush += `${openTime} - ${closeTime}, `;
      }

      arrayOfStrings.push(`${day}: ${stringToPush.slice(0, -2)}`);
    }

    return arrayOfStrings;
  }, [selectedLocation?.hoursOfOperation]);

  const supportedSocialMediaLinks: {
    socialMediaKey: string;
    customIcon: boolean;
  }[] = useMemo(
    () => [
      { socialMediaKey: "facebook", customIcon: false },
      { socialMediaKey: "instagram", customIcon: false },
      { socialMediaKey: "yelp", customIcon: false },
      { socialMediaKey: "doordash", customIcon: true },
      { socialMediaKey: "ubereats", customIcon: true },
      { socialMediaKey: "grubhub", customIcon: true },
      { socialMediaKey: "postmates", customIcon: true },
    ],
    [],
  );

  const shouldShowSocialMediaLinks = useMemo(() => {
    if (!restaurant) {
      return false;
    }

    if (!restaurant.socialMediaLinks) {
      return false;
    }

    for (const socialMedia of supportedSocialMediaLinks) {
      const { socialMediaKey } = socialMedia;

      if (restaurant.socialMediaLinks[socialMediaKey]) {
        return true;
      }
    }

    return false;
  }, [restaurant, supportedSocialMediaLinks]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in Footer"),
    );
    return <div />;
  }

  return (
    <div className={styles.Footer} data-testid={"footer"}>
      <div className={styles.footerTop}>
        <div className={styles.footerLeft}>
          {restaurant.restaurantSettings.isAddressShown &&
            restaurant.locations.length > 0 && (
              <div className={styles.footerSection} data-testid="address">
                <h4 className={styles.footerHeading}>Locations</h4>
                {restaurant.locations.map((location, index) => {
                  const address = `${location.line1}, ${location.city}, ${location.state} ${location.postalCode}`;
                  const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(address)}`;
                  return (
                    <div key={index} className={styles.locationItem}>
                      <a
                        href={googleMapsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(styles.footerText, styles.link)}
                        data-testid={`address-${index}`}
                      >
                        <p data-testid={`line1`}>{location.line1}</p>
                        {location.line2.length > 0 && (
                          <p data-testid={`line2`}>{location.line2}</p>
                        )}
                        <p data-testid={`city-state-country-zip`}>
                          {`${location.city}, ${location.state} ${location.country} ${location.postalCode}`}
                        </p>
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          <div
            className={styles.footerSection}
            data-testid="hours-of-operation"
          >
            <h4 className={styles.footerHeading}>Hours of Operation</h4>
            {hoursOfOperationTextArray.map((hoursOfOperationText, i) => (
              <p
                data-testid={`${daysOfWeek[i].toLowerCase()}`}
                className={styles.footerText}
                key={i}
              >
                {hoursOfOperationText}
              </p>
            ))}
          </div>
        </div>
        <div className={styles.footerCenter}>
          <div className={styles.footerSection} data-testid="contact">
            <h4 className={styles.footerHeading}>Contact Us</h4>
            <a
              href={`tel://${restaurant.contactPhoneNumber}`}
              className={classNames(styles.footerText, styles.link)}
              data-testid="phone-number"
              onClick={() =>
                logFooterContactLinkClickedToAnalytics("phone", customer?.id)
              }
            >
              {formatPhoneNumber(restaurant.contactPhoneNumber)}
            </a>
          </div>
          {shouldShowSocialMediaLinks ? (
            <div
              className={styles.footerSection}
              data-testid="social-media-links"
            >
              <h4 className={styles.footerHeading}>Connect with Us</h4>
              <div className={styles.socialIconsContainer}>
                {supportedSocialMediaLinks.map((socialMedia) => {
                  const { socialMediaKey, customIcon } = socialMedia;

                  if (
                    restaurant.socialMediaLinks &&
                    restaurant.socialMediaLinks[socialMediaKey]
                  ) {
                    if (customIcon) {
                      return (
                        <CustomSocialIcon
                          key={socialMediaKey}
                          conatinerClassName={styles.socialMediaLink}
                          testId={socialMediaKey}
                          socialMediaKey={socialMediaKey}
                          socialMediaLink={
                            restaurant.socialMediaLinks[socialMediaKey]
                          }
                          onClick={() => {
                            logFooterSocialMediaLinkClickedToAnalytics(
                              customer?.id,
                              socialMediaKey,
                            );
                          }}
                        />
                      );
                    }

                    return (
                      <SocialIcon
                        key={socialMediaKey}
                        data-testid={socialMediaKey}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialMediaLink}
                        url={restaurant.socialMediaLinks[socialMediaKey]}
                        onClick={() => {
                          logFooterSocialMediaLinkClickedToAnalytics(
                            customer?.id,
                            socialMediaKey,
                          );
                        }}
                      />
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <Image
              data-testid="logo"
              src={restaurant.logoUrl}
              alt="logo"
              className={styles.footerLogo}
            />
          )}
        </div>
        {restaurant.isOwnerManaged ? (
          <div className={styles.footerRight}>
            <div className={styles.footerSection} data-testid="app-links">
              <h4 className={styles.footerHeading}>Download Our Mobile App</h4>
              <div className={styles.storeImageContainer}>
                <a
                  data-testid="app-store"
                  href={getClientConfig("iOSAppStoreUrl")}
                  target="_blank"
                  onClick={() =>
                    logFooterAppLinkClickedToAnalytics(
                      "app_store",
                      customer?.id,
                    )
                  }
                >
                  <Image
                    src={getDownloadFromTheAppStoreImageURL()}
                    className={styles.storeImage}
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  data-testid="google-play"
                  href={getClientConfig("androidGooglePlayStoreUrl")}
                  target="_blank"
                  onClick={() =>
                    logFooterAppLinkClickedToAnalytics(
                      "google_play",
                      customer?.id,
                    )
                  }
                >
                  <Image
                    src={getGetItOnGooglePlayImageURL()}
                    className={styles.storeImage}
                    alt="Get It On Google Play"
                  />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.footerRight} />
        )}
      </div>
      <div className={styles.footerBottom}>
        {!restaurant.isOwnerManaged ? (
          <p
            className={styles.footerText}
            data-testid={"non-owner-managed-disclaimer"}
          >
            <p className={styles.bold}>{`Disclaimer: `}</p>
            {`All orders are routed directly to the restaurant's POS through the Platter systems. Are you the owner of ${restaurant.restaurantName}? `}
            <span className={styles.footerSpan}>
              <a
                onClick={() =>
                  logClickFooterDisclaimerToAnalytics(customer?.id)
                }
                href={`mailto://admin@joinplatter.com`}
                className={classNames(styles.footerText, styles.link)}
              >{`Get in touch`}</a>
            </span>
            {` with us now to manage your menu and claim this website for your restaurant.`}
          </p>
        ) : (
          <p className={styles.footerText}>
            {`${restaurant.restaurantName} is a `}
            <span className={styles.footerSpan}>
              <a
                onClick={() =>
                  logFooterPlatterLinkClickedToAnalytics(customer?.id)
                }
                target="_blank"
                href={`https://joinplatter.com`}
                className={classNames(styles.footerText, styles.link)}
              >{`Platter`}</a>
            </span>
            {` powered restaurant © 2024 Platter All Rights Reserved.`}
          </p>
        )}
      </div>
    </div>
  );
};
