import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useState } from "react";
import { useDesign } from "src/common/getDesign";
import { Button } from "src/components";
import ReactLoading from "react-loading";
import styles from "src/pages/CateringInvoice/PayButton/styles.module.scss";
import {
  logCateringCheckoutFailureToAnalytics,
  logCateringCheckoutInitiatedToAnalytics,
  logCateringCheckoutSuccessToAnalytics,
} from "src/common/analytics";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { getCurrentEnvironment } from "src/config/getConfig";
import { stripeErrors } from "src/common/stripeErrors";
import { captureManualSentryException } from "src/common/sentry";

interface ComponentProps {
  withStripeHooks: boolean;
  cateringInvoiceId: string;
  onPaymentSuccess: () => void;
}

export const PayButton = ({
  withStripeHooks,
  cateringInvoiceId,
  onPaymentSuccess,
}: ComponentProps) => {
  const design = useDesign();
  const stripe = withStripeHooks ? useStripe() : null;
  const elements = withStripeHooks ? useElements() : null;

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );

  const [isPaying, setIsPaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const handlePayment = useCallback(async () => {
    setIsPaying(true);
    setErrorMessage(undefined);

    if (stripe && cateringInvoiceId && elements) {
      logCateringCheckoutInitiatedToAnalytics(customer?.id, cateringInvoiceId);

      const resultFromStripe = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url:
            getCurrentEnvironment() === "test"
              ? "https://joinplatter.com"
              : window.location.origin,
        },
      });

      const { error } = resultFromStripe;

      if (error && error.message) {
        const { message } = error;

        setErrorMessage(message);

        if (!stripeErrors.includes(message)) {
          captureManualSentryException(message);
        }

        logCateringCheckoutFailureToAnalytics(
          customer?.id,
          cateringInvoiceId,
          message,
        );
      } else {
        logCateringCheckoutSuccessToAnalytics(customer?.id, cateringInvoiceId);

        onPaymentSuccess();
        setIsPaying(false);
      }
    }

    setIsPaying(false);
  }, [stripe, cateringInvoiceId, elements, customer, onPaymentSuccess]);

  if (isPaying) {
    return (
      <div className={styles.loadingContainer}>
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={30}
          width={30}
        />
      </div>
    );
  }

  return (
    <>
      {isPaying ? (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={30}
            width={30}
          />
        </div>
      ) : (
        <Button
          testId="pay-button"
          className={styles.PayButton}
          onClick={handlePayment}
        >
          <h3 className={styles.payButtonText}>Pay</h3>
        </Button>
      )}
      {errorMessage && (
        <p data-testid="error-message" className={styles.errorMessage}>
          {errorMessage}
        </p>
      )}
    </>
  );
};
