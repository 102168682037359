import { PageTitle, TextInput } from "src/components";
import styles from "src/pages/Checkout/styles.module.scss";
import { CheckoutOrderDetails } from "src/pages/Checkout/CheckoutOrderDetails/CheckoutOrderDetails";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getEnvVariable } from "src/config/getConfig";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { useScrollToTop } from "src/common/useScrollToTop";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMenuPath } from "src/Router/routes";
import { animateScroll as scroll } from "react-scroll";
import { isValidPhoneNumber } from "src/common/validation";
import { useDesign } from "src/common/getDesign";
import palette from "src/common/styles/palette.module.scss";

export const Checkout = () => {
  useScrollToTop();
  const design = useDesign();
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const stripePromise = loadStripe(getEnvVariable("STRIPE_PUBLISHABLE_KEY"), {
    stripeAccount: restaurant?.stripeConnectedAccountId || undefined,
  });

  const checkoutSession = useSelector((state: State) => state.checkoutSession);
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkoutSession.order) {
      navigate(getMenuPath());
    }
  }, [checkoutSession]);

  const [nameForTheOrder, setNameForTheOrder] = useState("");
  const [phoneForTheOrder, setPhoneForTheOrder] = useState("");
  const [nameError, setNameError] = useState<string | undefined>();
  const [phoneError, setPhoneError] = useState<string | undefined>();

  const areFieldsValid = useCallback(() => {
    let isValid = true;

    if (!customer && nameForTheOrder.trim() === "") {
      setNameError("Please enter a name for this order.");
      scroll.scrollToTop();
      isValid = false;
    }

    if (!customer && phoneForTheOrder.trim() === "") {
      setPhoneError("Please enter a phone number for this order.");
      scroll.scrollToTop();
      isValid = false;
    }

    if (!customer && !isValidPhoneNumber(phoneForTheOrder)) {
      setPhoneError("Please enter a valid phone number.");
      scroll.scrollToTop();
      isValid = false;
    }

    return isValid;
  }, [customer, nameForTheOrder, phoneForTheOrder]);

  if (!checkoutSession.order) {
    return <div />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: checkoutSession.paymentIntentClientSecret,
        appearance: {
          theme: "stripe" as "stripe" | "night",
          variables: {
            colorPrimary: design.buttonColor,
            colorBackground: design.cardColor,
            colorText: design.textColor,
            colorDanger: palette.red,
            spacingUnit: "4px",
            borderRadius: "4px",
          },
        },
      }}
    >
      <div className={styles.Checkout} data-testid="checkout-container">
        <div className={styles.checkoutContainer}>
          <div className={styles.checkoutPaymentContainer}>
            <PageTitle title="Checkout" className={styles.title} />
            {!customer && (
              <>
                <TextInput
                  className={styles.nameInput}
                  testId="name-for-order-input"
                  label="Name for the Order"
                  autoComplete="given-name"
                  value={nameForTheOrder}
                  onChangeText={(newText) => {
                    setNameError(undefined);
                    setNameForTheOrder(newText);
                  }}
                  placeholder="Enter a name for this order..."
                  errorMessage={nameError}
                />
                <TextInput
                  className={styles.phoneInput}
                  testId="phone-for-order-input"
                  label="Phone Number for the Order"
                  autoComplete="tel"
                  value={phoneForTheOrder}
                  onChangeText={(newText) => {
                    setPhoneError(undefined);
                    setPhoneForTheOrder(newText);
                  }}
                  placeholder="Enter a phone number for this order..."
                  errorMessage={phoneError}
                />
              </>
            )}
            <h3 className={styles.sectionTitle}>Payment Information</h3>
            <PaymentElement />
          </div>
          <CheckoutOrderDetails
            className={styles.orderDetails}
            areFieldsValid={areFieldsValid}
            nameForTheOrder={nameForTheOrder}
            phoneForTheOrder={phoneForTheOrder}
          />
        </div>
      </div>
    </Elements>
  );
};
