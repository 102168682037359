// Utility function to help with deals
import { DealFragment, DEAL_TYPE, DEAL_VALUE_TYPE } from "src/state/deal/types";
import { ItemFragment } from "src/state/item/types";
import { OptionFragment } from "src/state/option/types";
import { CartItemOptionsSelected } from "src/state/cart/types";
import { post } from "src/api/server";
import {
  DeliveryQuote,
  DeliveryQuoteError,
} from "src/common/types/DeliveryQuote";

export const getDefaultOptionsForItem = (optionsForItem: OptionFragment[]) => {
  const initialOptionsSelected: CartItemOptionsSelected = {};

  for (const option of optionsForItem) {
    for (const optionValue of option.optionValues) {
      if (optionValue.isDefault) {
        initialOptionsSelected[option.id] = [optionValue];
      }
    }
  }

  return initialOptionsSelected;
};

export const getOptionValuePrice = (
  optionValuesSelection: CartItemOptionsSelected,
) => {
  let price = 0;

  for (const optionId in optionValuesSelection) {
    const optionValuesSelected = optionValuesSelection[optionId];

    for (const optionValue of optionValuesSelected) {
      price += optionValue.price;
    }
  }

  return roundToNearestCent(price);
};

export const getItemPriceAfterDealApplied = (
  deal: DealFragment,
  item: ItemFragment,
  itemIndexFromSelected: number,
) => {
  let price = item.price;

  if (deal.type === DEAL_TYPE.COMBO) {
    price = deal.dealValue / deal.entityTypes.length;
  } else if (deal.type === DEAL_TYPE.BOGO && itemIndexFromSelected === 1) {
    if (deal.dealValueType === DEAL_VALUE_TYPE.PERCENT) {
      price *= (100 - deal.dealValue) / 100;
    } else if (deal.dealValueType === DEAL_VALUE_TYPE.DOLLAR) {
      price -= deal.dealValue;
    }
  } else if (deal.type === DEAL_TYPE.AMOUNT_OFF) {
    if (deal.dealValueType === DEAL_VALUE_TYPE.PERCENT) {
      price *= (100 - deal.dealValue) / 100;
    } else if (deal.dealValueType === DEAL_VALUE_TYPE.DOLLAR) {
      price -= deal.dealValue;
    }
  } else if (deal.type === DEAL_TYPE.SET_PRICE) {
    price = deal.dealValue;
  }

  if (price < 0) {
    price = 0;
  }

  return price;
};

export const getTotalPriceAfterDealAppliedWithoutOptions = (
  deal: DealFragment,
  items: ItemFragment[],
) => {
  let newPrice = 0;

  if (deal.type === DEAL_TYPE.AMOUNT_OFF) {
    newPrice =
      deal.dealValueType === DEAL_VALUE_TYPE.PERCENT
        ? items[0].price * ((100 - deal.dealValue) / 100)
        : items[0].price - deal.dealValue;
  } else if (deal.type === DEAL_TYPE.SET_PRICE) {
    newPrice = deal.dealValue;
  } else if (deal.type === DEAL_TYPE.BOGO) {
    const priceOfFirstItem = items[0].price;
    let priceOfSecondItem = items[1].price;

    if (deal.dealValueType === DEAL_VALUE_TYPE.PERCENT) {
      priceOfSecondItem = priceOfSecondItem * ((100 - deal.dealValue) / 100);
    } else {
      priceOfSecondItem = priceOfSecondItem - deal.dealValue;
    }

    if (priceOfSecondItem < 0) {
      priceOfSecondItem = 0;
    }

    newPrice = priceOfFirstItem + priceOfSecondItem;
  } else if (deal.type === DEAL_TYPE.COMBO) {
    newPrice = deal.dealValue;
  }

  if (newPrice < 0) {
    newPrice = 0;
  }

  return roundToNearestCent(newPrice);
};

export const roundToNearestCent = (price: number) => {
  return Math.round(price * 100) / 100;
};

export const calculateSalesTaxForSubtotal = async (
  subtotal: number,
  locationId: string,
) => {
  const response = (await post({
    endpoint: "/order/get_sales_tax_amount_for_subtotal",
    params: {
      subtotal,
      locationId,
    },
  })) as {
    salesTaxAmount: number;
  };

  return response.salesTaxAmount;
};

export const calculateSalesTaxRateForCateringRequest = async (
  cateringRequestId: string,
) => {
  const response = (await post({
    endpoint: "/catering/get_sales_tax_rate_for_catering_request",
    params: {
      cateringRequestId,
    },
  })) as {
    salesTaxRate: number;
  };

  return response.salesTaxRate;
};

export const calculateDeliveryQuote = async (
  restaurantId: string,
  customerAddress: string,
  orderSubtotal: number,
  locationId: string,
  deliveryTime?: string,
): Promise<DeliveryQuote | DeliveryQuoteError> => {
  const response = (await post({
    endpoint: "/order/get_delivery_quote",
    params: {
      restaurantId,
      customerAddress,
      orderSubtotal,
      locationId,
      deliveryTime,
    },
  })) as DeliveryQuote | DeliveryQuoteError;

  return response;
};
