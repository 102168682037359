import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { logTaxesAndFeesToolTipClickedToAnalytics } from "src/common/analytics";
import { CreateDeliveryParams } from "src/common/order";
import { captureManualSentryException } from "src/common/sentry";
import { getServiceFee } from "src/common/serviceFee";
import { BasicModal } from "src/components";
import styles from "src/pages/Checkout/CheckoutOrderDetails/CheckoutPriceDetails/styles.module.scss";
import { CartItemFragment } from "src/state/cart/types";
import { DEAL_VALUE_TYPE } from "src/state/deal/types";
import { ORDER_TYPE } from "src/state/order/types";
import { State } from "src/state/state";

interface ComponentProps {
  onTotalCalculated: (total: number) => void;
}

export const CheckoutPriceDetails = ({ onTotalCalculated }: ComponentProps) => {
  const [isFeesModalVisible, setIsFeesModalVisible] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const restaurantWideDiscount = useSelector(
    (state: State) =>
      restaurant && state.restaurantWideDiscounts[restaurant.id],
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.cart);
  const checkoutSession = useSelector((state: State) => state.checkoutSession);
  const restaurantTipAmount = useSelector(
    (state: State) => state.checkoutSession.order.tipAmount,
  );
  const deliveryDetails = useSelector(
    (state: State) => state.checkoutSession.delivery,
  );

  const cartItems = useMemo(() => {
    return Object.values(cart) as CartItemFragment[];
  }, [cart]);

  const cartTotalBeforePoints = useMemo(
    () => cartItems && cartItems.reduce((a, v) => a + v.totalPrice, 0),
    [cartItems],
  );

  const pointsToUseInDollars = useMemo(() => {
    if (customer && restaurant) {
      const minimumSpendRequiredInDollars = restaurant.loyalty
        .pointsSystemAmountSpend as number;
      const pointsValueAtMinimum = restaurant.loyalty
        .pointsSystemAmountEarn as number;

      const minimumSpendRequiredInPoints = minimumSpendRequiredInDollars * 100;

      const pointsValueInDollars =
        Math.floor(customer.points / minimumSpendRequiredInPoints) *
        pointsValueAtMinimum;

      if (pointsValueInDollars > cartTotalBeforePoints) {
        return Math.ceil(cartTotalBeforePoints);
      }

      return pointsValueInDollars;
    }

    return 0;
  }, [customer, cartTotalBeforePoints]);

  const valueForRestaurantWideDiscount = useMemo(() => {
    if (restaurantWideDiscount) {
      let subtotal = cartTotalBeforePoints;

      if (checkoutSession.order.pointsUsedInDollars > 0) {
        subtotal -= pointsToUseInDollars;
      }

      if (restaurantWideDiscount.dealValueType === DEAL_VALUE_TYPE.DOLLAR) {
        return restaurantWideDiscount.dealValue;
      } else {
        return subtotal * (restaurantWideDiscount.dealValue / 100);
      }
    }
  }, [
    cartTotalBeforePoints,
    restaurantWideDiscount,
    pointsToUseInDollars,
    checkoutSession,
  ]);

  const subtotal = useMemo(() => {
    let finalAmount = cartTotalBeforePoints;

    if (checkoutSession.order.pointsUsedInDollars > 0) {
      finalAmount -= pointsToUseInDollars;
    }

    if (valueForRestaurantWideDiscount) {
      finalAmount -= valueForRestaurantWideDiscount;
    }

    return finalAmount;
  }, [
    cartTotalBeforePoints,
    checkoutSession,
    pointsToUseInDollars,
    valueForRestaurantWideDiscount,
  ]);

  const salesTax = useMemo(() => {
    return checkoutSession.order.salesTax;
  }, [checkoutSession]);

  const serviceFee = useMemo(() => {
    return subtotal > 0 &&
      restaurant &&
      checkoutSession.order.orderType !== ORDER_TYPE.VIRTUAL_BRAND
      ? getServiceFee(
          subtotal,
          subtotal + salesTax + restaurantTipAmount,
          checkoutSession.order.orderType === ORDER_TYPE.DELIVERY,
          restaurant.isOwnerManaged,
        )
      : 0;
  }, [subtotal, salesTax, restaurantTipAmount, restaurant, checkoutSession]);

  const total = useMemo(() => {
    if (deliveryDetails) {
      const deliveryDetails = checkoutSession.delivery as CreateDeliveryParams;

      return (
        subtotal +
        salesTax +
        restaurantTipAmount +
        serviceFee +
        deliveryDetails.driverTipInDollars +
        deliveryDetails.deliveryQuote.totalFee
      );
    } else {
      return subtotal + salesTax + restaurantTipAmount + serviceFee;
    }
  }, [subtotal, salesTax, restaurantTipAmount, serviceFee, deliveryDetails]);

  useEffect(() => {
    onTotalCalculated(total);
  }, [onTotalCalculated, total]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in CheckoutPriceDetails"),
    );
    return <div />;
  }

  return (
    <div className={styles.CheckoutPriceDetails}>
      <div className={styles.priceRow}>
        <h4 className={styles.priceRowText}>Subtotal</h4>
        <h4 className={styles.priceRowText} data-testid="subtotal">
          ${cartTotalBeforePoints.toFixed(2)}
        </h4>
      </div>
      {checkoutSession.order.pointsUsedInDollars > 0 && (
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Points Used</h4>
          <h4
            data-testid="points-used"
            className={styles.priceRowText}
          >{`($${pointsToUseInDollars.toFixed(2)})`}</h4>
        </div>
      )}
      {valueForRestaurantWideDiscount && (
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Discount</h4>
          <h4
            data-testid="restaurant-wide-discount"
            className={styles.priceRowText}
          >{`-$${valueForRestaurantWideDiscount.toFixed(2)}`}</h4>
        </div>
      )}
      {deliveryDetails && (
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Delivery Fee</h4>
          <h4 className={styles.priceRowText} data-testid="delivery-fee">
            ${deliveryDetails.deliveryQuote.totalFee.toFixed(2)}
          </h4>
        </div>
      )}
      <div className={styles.priceRow}>
        <div className={styles.taxesAndFeesRow}>
          <h4 className={styles.priceRowText}>
            {deliveryDetails ? "Taxes & Other Fees" : "Taxes & Fees"}
          </h4>
          <FontAwesomeIcon
            data-testid="taxes-and-fees-info-icon"
            className={styles.circleInfoIcon}
            icon={faCircleInfo}
            onClick={() => {
              logTaxesAndFeesToolTipClickedToAnalytics(
                customer?.id,
                checkoutSession.order.orderType,
              );
              setIsFeesModalVisible(true);
            }}
          />
        </div>
        <h4 className={styles.priceRowText} data-testid="sales-tax">
          ${(salesTax + serviceFee).toFixed(2)}
        </h4>
      </div>
      {(restaurant.restaurantSettings.isRestaurantTipsEnabled ||
        deliveryDetails) && (
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>
            {deliveryDetails ? "Total Tips" : "Tips"}
          </h4>
          <h4 className={styles.priceRowText} data-testid="tip-amount">
            $
            {deliveryDetails
              ? (
                  restaurantTipAmount + deliveryDetails.driverTipInDollars
                ).toFixed(2)
              : restaurantTipAmount.toFixed(2)}
          </h4>
        </div>
      )}
      <div className={styles.priceRow}>
        <h4 className={styles.orderTotalText}>Order Total</h4>
        <h4 className={styles.orderTotalText} data-testid="total">
          ${total.toFixed(2)}
        </h4>
      </div>
      <BasicModal
        height="300"
        testId="taxes-and-fees-modal"
        isModalVisible={isFeesModalVisible}
        title={"Taxes & Fees"}
        message={
          <>
            <p data-testid="modal-sales-tax">{`Sales Tax: $${salesTax.toFixed(
              2,
            )}`}</p>
            <p data-testid="modal-service-fee">{`Service Fee: $${serviceFee.toFixed(
              2,
            )}`}</p>
            <p>
              {restaurant.isOwnerManaged
                ? "We use Platter to power our amazing website & mobile app. This tiny service fee helps keep the lights on."
                : "Platter is used to power online ordering for this restaurant. This tiny service fee helps keep the lights on."}
            </p>
          </>
        }
        onConfirm={() => setIsFeesModalVisible(false)}
        onClickOutside={() => setIsFeesModalVisible(false)}
        confirmText="Ok"
      />
    </div>
  );
};
