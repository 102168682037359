// Catering form submission functions
import { post } from "src/api/server";

export const createCateringRequestInDatabase = async (
  restaurantId: string,
  customerName: string,
  companyName: string,
  emailAddress: string,
  phoneNumber: string,
  estimatedNumberOfGuests: number,
  estimatedBudget: number,
  eventDate: string,
  eventLocation: string,
  zipCode: string,
  notes: string,
): Promise<void> => {
  await post({
    endpoint: "/catering/create_catering_request",
    params: {
      restaurantId,
      customerName,
      companyName,
      emailAddress,
      phoneNumber,
      estimatedNumberOfGuests,
      estimatedBudget,
      eventDate,
      eventLocation,
      zipCode,
      notes,
    },
  });
};

export const createPaymentIntentForCateringInvoice = async (
  cateringInvoiceId: string,
  type: "card" | "bank_account",
) => {
  const response = await post({
    endpoint: "/catering/create_payment_intent_for_catering_invoice",
    params: {
      cateringInvoiceId,
      type,
    },
  });

  return response as {
    ephemeralKey: string;
    paymentIntentClientSecret: string;
    paymentIntentId: string;
    stripeCustomerId: string;
  };
};
