import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { captureManualSentryException } from "src/common/sentry";
import { useScrollToTop } from "src/common/useScrollToTop";
import { PageTitle, SkeletonLoading } from "src/components";
import styles from "src/pages/Receipt/styles.module.scss";
import { getSpecificOrderAction } from "src/state/order/actions";
import { State } from "src/state/state";
import { OrderItem } from "src/pages/Receipt/OrderItem/OrderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCar,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { ORDER_TYPE, ORDER_STATUS } from "src/state/order/types";
import { formatISOStringToHoursAndMinutes } from "src/common/date";
import { orderStatusText } from "src/common/constants";
import classNames from "classnames";
import { logDeliveryTrackingClickedToAnalytics } from "src/common/analytics";

type ReceiptUrlParams = "orderId";

export const Receipt = () => {
  useScrollToTop();

  const { orderId } = useParams<ReceiptUrlParams>();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      orderId &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  const orderPickupLabelMap = useMemo(
    () => ({
      [ORDER_TYPE.PICKUP]: "Pickup",
      [ORDER_TYPE.DELIVERY]: "Delivery",
      [ORDER_TYPE.VIRTUAL_BRAND]: "Virtual Brand",
    }),
    [],
  );

  const discountValue = useMemo(() => {
    if (order && order.restaurantWideDiscountId) {
      const {
        priceAfterRestaurantWideDiscount,
        pointsUsedInDollars,
        subtotal,
      } = order;

      const discountValue =
        subtotal - pointsUsedInDollars - priceAfterRestaurantWideDiscount;

      return discountValue;
    }
  }, [order]);

  const fetchOrder = useCallback(async () => {
    if (restaurant && orderId) {
      await getSpecificOrderAction(restaurant.id, orderId)(dispatch);
    }

    setIsLoading(false);
  }, [orderId, restaurant, dispatch]);

  useEffect(() => {
    if (!order) {
      fetchOrder();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.Receipt}>
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  if (!orderId || !restaurant || !order) {
    captureManualSentryException(
      new Error("orderId or restaurant or order is not defined in Receipt"),
    );
    return <div />;
  }

  return (
    <div
      className={styles.Receipt}
      data-testid={`receipt-container-${order.id}`}
    >
      <PageTitle title={`Order #${order.orderNumber}`} testId="page-title" />
      <div className={styles.orderDetails} data-testid="order-details">
        <div className={styles.orderDetailsInfo}>
          <h1 className={styles.title}>Order Details</h1>
          <div className={styles.orderDetailInfoRow}>
            <FontAwesomeIcon className={styles.icon} icon={faClock} />
            <p className={styles.orderDetailsInfoName}>{`Order Placed At: `}</p>
            <p
              className={styles.orderDetailsInfoSelection}
              data-testid="placed-at"
            >
              {formatISOStringToHoursAndMinutes(order.createdAt)}
            </p>
          </div>
          <div className={styles.orderDetailInfoRow}>
            <FontAwesomeIcon className={styles.icon} icon={faBagShopping} />
            <p className={styles.orderDetailsInfoName}>{`Order Type: `}</p>
            <p
              className={styles.orderDetailsInfoSelection}
              data-testid="order-type"
            >
              {orderPickupLabelMap[order.orderType]}
            </p>
          </div>
          <div className={styles.orderDetailInfoRow}>
            <FontAwesomeIcon className={styles.icon} icon={faClock} />
            <p className={styles.orderDetailsInfoName}>
              {order.delivery ? `ETA: ` : `Estimated Pickup Time: `}
            </p>
            <p
              className={styles.orderDetailsInfoSelection}
              data-testid="pickup-time"
            >
              {order.delivery
                ? formatISOStringToHoursAndMinutes(
                    order.delivery.estimatedDeliveryTime,
                  )
                : formatISOStringToHoursAndMinutes(order.pickupTime)}
            </p>
          </div>
          {order.delivery && order.delivery.trackingUrl && (
            <div className={styles.orderDetailInfoRow}>
              <FontAwesomeIcon className={styles.icon} icon={faCar} />
              <a
                data-testid="track-delivery"
                className={styles.orderDetailsInfoSelection}
                target="_blank"
                href={order.delivery.trackingUrl}
                onClick={() => {
                  logDeliveryTrackingClickedToAnalytics(customer?.id, orderId);
                }}
              >
                {"Track Your Delivery"}
              </a>
            </div>
          )}
          <div
            className={classNames(styles.statusPill, {
              [styles.cancelled]: order.status === ORDER_STATUS.CANCELLED,
            })}
          >
            <h4 className={styles.pillText} data-testid={"status"}>
              {orderStatusText[order.status]}
            </h4>
          </div>
        </div>
        <div className={styles.orderItemsContainer}>
          {order.orderItems.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              orderItem={orderItem}
              testId={`order-item-${orderItem.itemId}`}
            />
          ))}
        </div>
        {order.additionalNotes && order.additionalNotes !== "" && (
          <div className={styles.additionalNotesContainer}>
            <h3 className={styles.additionalNotesLabel}>Additional Notes</h3>
            <p
              className={styles.additionalNotesText}
              data-testid="additional-notes"
            >
              {order.additionalNotes}
            </p>
          </div>
        )}
        <div className={styles.priceDetails}>
          <div className={styles.priceRow}>
            <h4 className={styles.priceRowText}>Subtotal</h4>
            <h4 className={styles.priceRowText} data-testid="subtotal">
              ${order.subtotal.toFixed(2)}
            </h4>
          </div>
          {order.delivery && (
            <div className={styles.priceRow}>
              <h4 className={styles.priceRowText}>Delivery Fee</h4>
              <h4 className={styles.priceRowText} data-testid="delivery-fee">
                $
                {(
                  order.delivery.deliveryFee +
                  order.delivery.paymentProcessingFee
                ).toFixed(2)}
              </h4>
            </div>
          )}
          {order.pointsUsedInDollars > 0 && (
            <div className={styles.priceRow}>
              <h4 className={styles.priceRowText}>Points Used</h4>
              <h4
                data-testid="points-used"
                className={styles.priceRowText}
              >{`($${order.pointsUsedInDollars.toFixed(2)})`}</h4>
            </div>
          )}
          {discountValue && (
            <div className={styles.priceRow}>
              <h4 className={styles.priceRowText}>Discount</h4>
              <h4
                data-testid="discount"
                className={styles.priceRowText}
              >{`-$${discountValue.toFixed(2)}`}</h4>
            </div>
          )}
          <div className={styles.priceRow}>
            <h4 className={styles.priceRowText}>
              {order.delivery ? "Taxes & Other Fees" : "Taxes & Fees"}
            </h4>
            <h4 className={styles.priceRowText} data-testid="taxes-and-fees">
              ${(order.salesTax + order.serviceFee).toFixed(2)}
            </h4>
          </div>
          {(restaurant.restaurantSettings.isRestaurantTipsEnabled ||
            order.delivery) && (
            <div className={styles.priceRow}>
              <h4 className={styles.priceRowText}>
                {order.delivery ? "Total Tips" : "Tips"}
              </h4>
              <h4 className={styles.priceRowText} data-testid="tip-amount">
                $
                {order.delivery
                  ? (
                      order.tipAmount + order.delivery.driverTipInDollars
                    ).toFixed(2)
                  : order.tipAmount.toFixed(2)}
              </h4>
            </div>
          )}
          <div className={styles.priceRow}>
            <h4 className={styles.orderTotalText}>Order Total</h4>
            <h4 className={styles.orderTotalText} data-testid="total">
              ${order.totalPriceCharged.toFixed(2)}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
