// Common utilities related to stripe

import { post } from "src/api/server";
import {
  CreateOrderItemAPIParams,
  ORDER_TYPE,
  OrderFragment,
} from "src/state/order/types";
import { DeliveryQuote } from "src/common/types/DeliveryQuote";

export interface OrderIntentResponse {
  paymentIntentClientSecret: string;
  ephemeralKey: string;
  paymentIntentId: string;
  order: OrderFragment;
}

export interface OrderIntentErrorResponse {
  message: "ORDER_ITEMS_NOT_VALID";
}

export interface CreateDeliveryParams {
  deliveryQuote: DeliveryQuote;
  customerAddress: string;
  deliveryInstructions: string;
  contactlessDelivery: boolean;
  driverTipInDollars: number;
}

export const createOrderIntentInDatabase = async (
  customerId: string | undefined,
  restaurantId: string,
  subtotalInDollars: number,
  salesTaxInDollars: number,
  pointsUsed: boolean,
  orderItems: CreateOrderItemAPIParams[],
  orderType: ORDER_TYPE,
  pickupTime: string | undefined,
  additionalNotes: string,
  tipAmountInDollars: number,
  delivery: CreateDeliveryParams | undefined,
  locationId: string,
): Promise<OrderIntentResponse | OrderIntentErrorResponse> => {
  const response = (await post({
    endpoint: "/order/create_order_intent",
    params: {
      customerId,
      restaurantId,
      subtotalInDollars,
      salesTaxInDollars,
      pointsUsed,
      orderItems,
      orderType,
      pickupTime,
      additionalNotes,
      tipAmountInDollars,
      delivery,
      locationId,
    },
  })) as OrderIntentResponse | OrderIntentErrorResponse;

  return response;
};

export const updateGuestCustomerName = async (
  guestCustomerId: string,
  name: string,
  phone: string,
) => {
  await post({
    endpoint: "/guest_customer/update",
    params: {
      id: guestCustomerId,
      name,
      phone,
    },
  });
};
